import req from "../http";

/**
 * 
 * 
 * @param { string } customer_name 
 *  @param { string } customer_phone 
 *  @param { string } customer_company 
 *  @param { string } customer_position 
 *  @param { string } customer_wechat_number 
 *  @param { string } customer_email 
 *  @param { string } remark 
 * @returns 
 */
export function addCustomerMessage(data) {
    return req.post("/api/ow/addCustomerMessage", data);
}

export function queryNewsList(data) {
    return req.post("/api/www/queryNewsList", data);
}

export function queryNewsBannerList(data) {
    return req.post("/api/www/queryNewsBannerList", data);
}
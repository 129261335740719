import axios from "axios";



const urlLocal = '/xx';
const urlMaster = "https://data-api.tasantea.com"; //正式环境
let urlOfWeb = window.location.host;


if (urlOfWeb == 'localhost:8001') {
    axios.defaults.baseURL = urlLocal;
} else if (urlOfWeb == 'www.tasantea.com') {
    axios.defaults.baseURL = urlMaster;
} else {
    axios.defaults.baseURL = urlMaster;
}

/* axios.defaults.timeout = 1500; */
axios.defaults.withCredentials = true;

// 添加请求拦截器
axios.interceptors.request.use(
    async config => {

        config.headers["Content-Type"] = "application/json";
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

// 添加响应拦截器
axios.interceptors.response.use(
    function(res) {


        return res;
    },
    function(error) {
        return Promise.reject(error);
    }
);

export default axios;